//获取当前url值
export const getUrlParamObj = (name : any) => {
	var obj : any = {};
	var url = window.location.href;
	if (url.indexOf('?') >= 0) {
		var arr1 = url.split('?');
		var arr2 = arr1[1].split('&');
		for (let i = 0; i < arr2.length; i++) {
			var res = arr2[i].split('=');
			obj[res[0]] = res[1];
		}
	}
	if (obj[name]) {
		return encodeURIComponent(obj[name]);
	} else {
		return "";
	}
}

export const deleteBlank = (obj : any) => {
	for (const key in obj) {
		const value = obj[key];
		if (value === null || value === undefined || value === "") {
			delete obj[key];
		}
	}
	return obj
}

//删除url参数
export const removeQueryParamFromUrl = (paramName: any) => {  
    // 获取当前URL的各部分
    var url = window.location.href;  
    var urlParts = url.split('?');  
    var path = urlParts[0];  
    var search = urlParts[1];  
  
    // 如果存在查询字符串
    if (search) {  
        // 使用URLSearchParams来解析和修改查询字符串
        var params = new URLSearchParams(search);  
        params.delete(paramName); // 删除指定的查询参数
          
        // 构建新的URL，并替换旧的URL（不刷新页面）
        var newUrl = path + '?' + params.toString();  
        if (newUrl.slice(-1) === '?') {  
            newUrl = newUrl.slice(0, -1); // 如果新的查询字符串为空，则删除问号
        }  
        history.replaceState({}, '', newUrl); // 更新浏览器的历史记录
    }  
}  

// 节流
export function throttle(fn : any, gapTime : any) {
	let _lastTime : any = null;

	return function () {
		let _nowTime = +new Date()
		if (_nowTime - _lastTime > gapTime || !_lastTime) {
			fn();
			_lastTime = _nowTime
		}
	}
}

// 防抖
export function debounce(func : any, delay : any) {
	let timeoutId : any;
	let args : any;
	let context : any;

	return function () {
		context = this;
		args = arguments;

		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(() => {
			func.apply(context, args);
		}, delay);
	};
}

// 获取设备参数
export const getDeviceModel = () => {
	let model : any = uni.getSystemInfoSync();
	if (model.uniPlatform == "web") {
		if (model.deviceType == "pc") {
			return "pc"
		} else {
			const ua = model.ua.toLowerCase();// 获取判断用的对象
			if (ua.match(/MicroMessenger/i) == 'micromessenger') {
				return 'weixin';
			}
			if (ua.match(/WeiBo/i) == 'weibo') {
				return 'weibo';
			}
			if (ua.match(/QQ/i) == 'qq') {
				return 'qq';
			}
		}
	}
}

// 获取设备
export const testEnv = () => {
	let ua = window.navigator.userAgent.toLowerCase();
	if ((ua as any).match(/MicroMessenger/i) == 'micromessenger') {
		return "wx";
	} else if ((ua as any).match(/AlipayClient/i) == 'alipayclient') {
		return "alipay";
	} else if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
	  // console.info('移动端')
	  return "wap";
	}else if(navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)){//ipd pro
	  return "wap";
	}
	// console.info('PC')
	return "pc";
	// return "wap";
}

// 格式时间
export const formatTime = (time : any) => {
	let date = new Date(time);

	// 获取年份  
	let year = date.getFullYear();

	// 获取月份（注意：月份是从0开始计数的，所以需要加1）  
	let month : any = date.getMonth() + 1;

	// 获取日期  
	let day : any = date.getDate();

	// 获取小时、分钟和秒  
	let hours : any = date.getHours();
	let minutes : any = date.getMinutes();
	let seconds : any = date.getSeconds();

	// 将所有数字补全为两位数（如果需要）  
	month = month < 10 ? '0' + month : month;
	day = day < 10 ? '0' + day : day;
	hours = hours < 10 ? '0' + hours : hours;
	minutes = minutes < 10 ? '0' + minutes : minutes;
	seconds = seconds < 10 ? '0' + seconds : seconds;

	// 组合成最终的日期时间字符串  
	let formattedDateTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

	return formattedDateTime;
}


// 格式时间
export const format24Time = (seconds : any) => {
	if (seconds === 0) {
		return '0秒';
	}

	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = Math.floor(seconds % 60);

	if (hours > 0) {
		return `${hours}时${minutes}分`;
	} else if (minutes > 0) {
		return `${minutes}分${remainingSeconds}秒`;
	} else {
		return `${remainingSeconds}秒`;
	}
}

// 表单校验规则
export const formRule = () => {
	return {
		phone: {
			rules: [
				{ required: true, errorMessage: '请输入您的手机号码' },
				{ pattern: /^\d{11}$/, errorMessage: '请输入正确手机号码' },
			],
			label: '手机号码',
			validateTrigger: 'submit'
		},
		verify_code: {
			rules: [
				{ required: true, errorMessage: '请输入您的验证码' },
				{ pattern: /^\d{4,6}$/, errorMessage: '请输入6位验证码' },
			],
			label: '验证码',
			validateTrigger: 'submit'
		},
		captcha_code: {
			rules: [
				{ required: true, errorMessage: '请输入您的验证码' },
				{ pattern: /^\d{4,6}$/, errorMessage: '请输入6位验证码' },
			],
			label: '验证码',
			validateTrigger: 'submit'
		},
		id_card: {
			rules: [
				{ required: true, errorMessage: '请输入您的身份证' },
				{ pattern: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, errorMessage: '请输入正确身份证' },
			],
			label: '身份证',
			validateTrigger: 'submit'
		},
		real_name: {
			rules: [
				{ required: true, errorMessage: '请输入您的姓名' },
				{ pattern: /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/, errorMessage: '请输入正确姓名' },
			],
			label: '姓名',
			validateTrigger: 'submit'
		},
		nick_name: {
			rules: [
				{ required: true, errorMessage: '请输入您的昵称' },
				{ pattern: /^.{5,12}$/, errorMessage: '昵称支持5～12个中文／英文／数字' },
			],
			label: '昵称',
			validateTrigger: 'submit'
		},
		price: {
			rules: [
				{ required: true, errorMessage: '请输入价格' },
				{ pattern: /^\d+(\.\d+)?$/, errorMessage: '请输入数字最多保留2位小数点' },
			],
			label: '价格',
			validateTrigger: 'submit'
		},
		plf_user_name: {
			rules: [
				{ required: true, errorMessage: '请输入账号' },
			],
			label: '账号',
			validateTrigger: 'submit'
		},
		plf_user_password: {
			rules: [
				{ required: true, errorMessage: '请输入密码' },
			],
			label: '密码',
			validateTrigger: 'submit'
		},
		server_name: {
			rules: [
				{ required: true, errorMessage: '请输入服务器' },
			],
			label: '服务器',
			validateTrigger: 'submit'
		},
		alipay_account: {
			rules: [
				{ required: true, errorMessage: '请输入您用于收款的支付宝账号' },
			],
			label: '服务器',
			validateTrigger: 'submit'
		}
	}
}

//打开url
export const openUrl = function (url : string) {
	window.open(url);
}

//生成uuid
export const uuid = () => {
	var s = [];
	var hexDigits = "0,1,2,3,4,5,6,7,8,9,a,b,c,d,e,f";
	for (var i = 0; i < 36; i++) {
		s[i] = hexDigits.split(',')[parseInt(Math.random() * 16)];
	}
	var uuid = s.join("");
	return uuid;
}

export const getCookiesObj = (): any => {
	// 获取cookie对象，以对象表示
	const cookies = {};
	if (document.cookie) {
		const objs = document.cookie.split("; ");
		for (const i in objs) {
		const index = objs[i].indexOf("=");
		const name = objs[i].substr(0, index);
		const value = objs[i].substr(index + 1, objs[i].length);
		(cookies as any)[name] = value;
		}
	}
	return cookies;
}

export const pcStyle = (app: any): string => {
	if(window.location.href.indexOf("/runtime/vue3/playground")>=0){//编辑情况
		return 'wap';
	}else{//线上情况
		if(app.env.isWeb){
			return 'pc';
		}else{
			return 'wap';
		}
	}
}

//拆分数组
export const chunkArray =(arr: any, chunkSize: any) => {  
    let result = [];  
    for (let i = 0; i < arr.length; i += chunkSize) {  
        let chunk = arr.slice(i, i + chunkSize);  
        result.push(chunk);  
    }  
    return result;  
}  

export const Cookie = () => {
	return {
	  // 设置cookie
	  set: (name:any, value:any, opts:any) => {
		// opts maxAge, path, domain, secure
		if (name && value) {
		  let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
		  // 可选参数
		  if (opts) {
			if (opts.maxAge) {
			  cookie += `; max-age=${opts.maxAge}`;
			}
			if (opts.path) {
			  cookie += `; path=${opts.path}`;
			}
			if (opts.domain) {
			  cookie += `; domain=${opts.domain}`;
			}
			if (opts.secure) {
			  cookie += '; secure';
			}
		  }
		  document.cookie = cookie;
		  return cookie;
		}
		return '';
	  },
	  // 获取cookie
	  get: (name:any): any => {
		let value = getCookiesObj()[name];
		
		if(value){
			return decodeURIComponent(getCookiesObj()[name])
		}else{
			return ""
		}
	  },
	  // 清除某个cookie
	  remove: (name:any): any => {
		if (getCookiesObj()[name]) {
		  document.cookie = `${name}=; max-age=0`;
		}
	  },

	  // 清除所有cookie
	  clear: () => {
		const cookies = getCookiesObj();
		const domain = window.location.host.substring(window.location.host.indexOf('.'));
		for (const key in cookies) {
		  document.cookie = `${key}=; max-age=0`;
		  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=${domain};`;  
		}
	  },
	  // 获取所有cookies
	  getCookies: (name:any) => getCookiesObj(),
	  // 解决冲突
	  noConflict: (name:any) => {
		if (name && typeof name === 'string') {
		  if (name && (window as any).cookie) {
			(window as any)[name] = (window as any).cookie;
			delete (window as any).cookie;
			return (window as any)[name];
		  }
		} else {
		  return (window as any).cookie;
		}
	  },
	};
  }