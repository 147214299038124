'use strict';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import host from './env_config';
import { Cookie, getUrlParamObj } from './common';


declare global {  
  interface Window {  
      Config: any; // 替换为 Config 的实际类型  
  }  
}

class HttpRequest {
  withCredentials: boolean;
  timeout: number;
  // #baseUrl
  constructor() {
    this.withCredentials = false;
    this.timeout = 60 * 60 * 24 * 1000;
  }

  getBaseUrl() {
    return host.api;
  }

  getConfig() {
    const config = {
      baseURL: this.getBaseUrl(),
      timeout: this.timeout,
      withCredentials: this.withCredentials,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };
    return config;
  }

  getParams(payload: { data: any; params?: any; method?: any }) {
    let { method, data } = payload;
    if(!data){
      data = {};
    }

    data.language = getUrlParamObj('language');//多语言

    if (['post', 'put', 'patch', 'delete'].indexOf(method.toLowerCase()) >= 0) {
      if(method == 'put'){
          payload.data = JSON.stringify(data)
      }else{
          payload.data = data
      }
    } else {
      payload.params = data;
      delete payload.data;
    }
    return payload;
  }

  checkStatus(status: any) {
    let errMessage = '';
    switch (status) {
      case 400:
        errMessage = '错误请求';
        break;
      case 401:
        errMessage = '未授权，请重新登录';
        break;
      case 403:
        errMessage = '拒绝访问';
        break;
      case 404:
        errMessage = '请求错误,未找到该资源';
        break;
      case 405:
        errMessage = '请求方法未允许';
        break;
      case 408:
        errMessage = '请求超时';
        break;
      case 500:
        errMessage = '服务器端出错';
        break;
      case 501:
        errMessage = '网络未实现';
        break;
      case 502:
        errMessage = '网络错误';
        break;
      case 503:
        errMessage = '服务不可用';
        break;
      case 504:
        errMessage = '网络超时';
        break;
      case 505:
        errMessage = 'http版本不支持该请求';
        break;
      default:
        errMessage = '连接错误';
    }
    return errMessage;
  }

  // 拦截处理
  setInterceptors(instance: {
    interceptors: {
      request: { use: (arg0: (config: any) => any, arg1: (error: any) => Promise<never>) => void };
      response: { use: (arg0: (res: any) => any, arg1: (error: any) => Promise<never>) => void };
    };
  }) {
    const that = this;

    // 请求拦截
    instance.interceptors.request.use(
      (config: { headers: { Authorization: string } }) => {
        if (!navigator.onLine) {
          ElMessage({
            message: '请检查您的网络是否正常',
            type: 'error',
            duration: 3 * 1000,
          });
          return Promise.reject(new Error('请检查您的网络是否正常'));
        }

        const token = Cookie().get('marketing_access_token');

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error: string | undefined) => Promise.reject(new Error(error)),
    );

    // 响应拦截
    instance.interceptors.response.use(
      (res: { data: any }) => {
        const result = res.data;
        const type = Object.prototype.toString.call(result);

        // 如果是文件流 直接返回
        if (type === '[object Blob]' || type === '[object ArrayBuffer]') {
          return res;
        }
        return result;
      },
      (error: any) => {
        if (error?.response) {
          error.message = error.response.data?.message || that.checkStatus(error.response.status);
        }

        const isTimeout = error.message.includes('timeout');
        ElMessage({
          message: isTimeout ? '网络请求超时' : error.message || '连接到服务器失败',
          type: 'error',
          duration: 2 * 1000,
        });

        switch (error?.response.data.code) {
          case 1001:
            Cookie().clear();
            break;
          case 2150://token 验证失败，可能已过期或者在黑名单
            Cookie().clear();
            break;
          case 45555://网络异常，请您重新登录
            Cookie().clear();
            break;
          default:
            break;
        }

        return Promise.reject(new Error(error.message));
      },
    );
  }

  request(options: any) {
    if(options.url.indexOf("special/v1/info") < 0){//初始化接口
      if(getUrlParamObj('isSkin') == "true"){
        return false;
      }
    }
    
    const instance = axios.create();
    const baseOpt = this.getConfig();
    const params = Object.assign({}, baseOpt, this.getParams(options));
    this.setInterceptors(instance);
    return instance(params);
  }
}

const http = new HttpRequest();
export default http;
