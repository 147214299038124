import http from "./http";
import { Cookie } from "./common";
import { sha256 } from "js-sha256";

// 获取当前专题页信息
export const GetSpecialInfo = () => {
  const special_info = window.Config.specialInfo;
  return special_info;
};

// 获取当前专题页自定义配置
export const GetSpecialCustomConfig = (lang: string | undefined = "zh-cn") => {
  const custom_data = window.Config.specialInfo.custom_data; /* [lang] */
  return custom_data;
};

// 获取像素配置
export const GetPixelConfig = (lang: string | undefined = "zh-cn") => {
  const custom_data = GetSpecialCustomConfig(lang);
  const pixel_data = custom_data?.items[0]?.pixel || "";
  return pixel_data;
};

// 海外上报方法
export const OverseasReportEvent = (
  component_name: string = "",
  event_name: string,
  _params: any = {}
) => {
  const custom_data = GetSpecialCustomConfig();
  const pixel_data = GetPixelConfig();
  let component_data = "";
  if (component_name) {
    component_data = custom_data?.items[0].items.find((item) => item.type === component_name);
  }
  const report_only_one = pixel_data?.config_report_custom_event_only_one === "1" ? true : false; // 仅上报一次
  const report_not_only_one_events =
    (pixel_data?.config_report_custom_event_not_only_one &&
      `${pixel_data?.config_report_custom_event_not_only_one}`.split(",")) ||
    []; // 例外

  console.log("pixel_data:", pixel_data);
  if (pixel_data) {
    try {
      let params = _params;
      const custom_event_name =
        (!!component_data
          ? component_data?.[`custom_event_${event_name}`]
          : pixel_data?.[`custom_event_${event_name}`]) || "";
      const gg_ad_event_id =
        (!!component_data
          ? component_data?.[`gg_ad_event_${event_name}`]
          : pixel_data?.[`gg_ad_event_${event_name}`]) || "";

      // 埋点
      function fnMD() {
        console.log("正在执行埋点上报...");
        // fb像素代码
        if (pixel_data.fb_pixel_id && custom_event_name) {
          const use_purchase_event = pixel_data?.fb_use_purchase_event === "1" ? true : false; // 开启购物车标准事件上报

          const allow_conversion_event =
            pixel_data?.fb_allow_conversion_event &&
            `${pixel_data?.fb_allow_conversion_event}`.split(",");
          if (
            pixel_data &&
            pixel_data?.fb_is_api_conversion === "1" &&
            allow_conversion_event.includes(custom_event_name)
          ) {
            let convert_params = _params;
            if (use_purchase_event && custom_event_name === "Purchase") {
              convert_params = {
                ..._params,
                product: pixel_data?.fb_purchase_content_ids || "", // 商品id
                currency: pixel_data?.fb_purchase_currency || "", // 币种
                money: pixel_data?.fb_purchase_value || "", // 金额
              };
            }
            FbConvertReport(event_name, convert_params);
          } else {
            console.log("正在FB普通埋点上报...");
            let event_id = "";
            let fb_normal_params = _params;
            if (use_purchase_event && custom_event_name === "Purchase") {
              fb_normal_params = {
                ..._params,
                content_ids: pixel_data?.fb_purchase_content_ids || "", // 商品id
                currency: pixel_data?.fb_purchase_currency || "", // 币种
                value: pixel_data?.fb_purchase_value || "", // 金额
              };
            }
            if (fb_normal_params?.eventID) {
              event_id = fb_normal_params.eventID;
              delete fb_normal_params?.eventID;
              fbq("trackCustom", custom_event_name, fb_normal_params, { eventID: event_id });
            } else {
              fbq("trackCustom", custom_event_name, fb_normal_params, {
                eventID: (window as any).custom_events_id_map?.[custom_event_name] || "",
              });
            }
          }
        }
        // gg像素ga4代码
        if (pixel_data.gg_ga4_pixel_id && custom_event_name) {
          console.log("正在谷歌GA4埋点上报...");
          (window as any).gtag("event", `${custom_event_name}`, {
            send_to: pixel_data.gg_ga4_pixel_id,
            ...params,
            // event_callback: callback,
          });
        }
        // gg广告像素代码
        if (pixel_data.gg_ad_pixel_id && custom_event_name) {
          console.log("正在谷歌广告埋点上报...");
          (window as any).gtag("event", "conversion", {
            send_to: `${pixel_data.gg_ad_pixel_id}/${gg_ad_event_id}`,
            ...params,
            // event_callback: callback,
          });
        }
        // tiktok像素代码
        if (pixel_data.tiktok_pixel_id && custom_event_name) {
          console.log("正在Tiktok埋点上报...");
          ttq.instance(pixel_data.tiktok_pixel_id).track(custom_event_name);
        }
        // twitter像素代码
        if (pixel_data.twitter_pixel_id && custom_event_name) {
          console.log("正在Twitter埋点上报...");
          twq("config", custom_event_name);
        }
      }

      // 去重
      if (report_only_one) {
        console.log("开启了自定义事件仅上报一次");
        if (report_not_only_one_events.includes(custom_event_name)) {
          console.log("当前事件" + custom_event_name + "被允许多次上报");
          fnMD();
        } else {
          console.log("当前事件" + custom_event_name + "仅能上报一次");
          SaveOne(
            `${GetSpecialInfo().id}-${custom_event_name}-${sha256(
              encodeURIComponent(window.location.pathname)
            )}`,
            function () {
              fnMD();
            }
          );
        }
      } else {
        fnMD();
      }
    } catch (error) {
      console.log("OverseasReportEvent error: ", error);
    }
  }
};

// 获取当前组件属性
export const GetOverseasCustomComponentData = (component_name) => {
  const custom_data = GetSpecialCustomConfig();
  const component_data = custom_data?.items[0].items.find((item) => item.type === component_name);
  if (!!component_data) {
    return component_data;
  } else {
    return "";
  }
};

export const FbConvertReport = async (event_name: string, params: any = {}) => {
  const custom_data = GetSpecialCustomConfig();
  const pixel_data = GetPixelConfig();
  const default_url = "/OfficialWebsite/fbConvert";
  const convert_url = pixel_data?.fb_conversion_api_url || default_url || "";
  const fid = pixel_data?.fb_conversion_fid || "";
  const fb_external_id = Cookie().get("fb_external_id") || "";
  console.log("正在请求FB转化API...");
  const res: any = await http.request({
    method: "POST",
    url: `/api${convert_url}`,
    data: {
      fid: fid,
      fbp: Cookie().get("_fbp") || "",
      fbc: Cookie().get("_fbc") || "",
      event: event_name,
      openid: fb_external_id,
      ...params,
    },
  });
  console.log("fb 转化api res: ", res);
  if (res.code === 1) {
  } else {
  }
};

// 生成随机ID
export const Fn_Guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};

export const SaveOne = (key: string, fun: any) => {
  //执行一次
  if (Cookie().get(key) === "1") {
    console.log("当前事件仅能上报一次且已经上报过");
    return false;
  } else {
    console.log("当前事件仅能上报一次且并未上报过");
    fun && fun();
    Cookie().set(key, "1");
    return true;
  }
};
