import EventEmitter from 'events';
import { template, cloneDeep, union } from 'lodash-es';
import { setValueByKeyPath, getDefaultValueFromFields, getValueByKeyPath, compiledNode, DATA_SOURCE_FIELDS_SELECT_VALUE_PREFIX, isObject, compiledCond, getNodes, getDepNodeIds, replaceChildNode } from '@tmagic/utils';

class DataSource extends EventEmitter {
  isInit = false;
  data = {};
  /** @tmagic/core 实例 */
  app;
  mockData;
  #type = "base";
  #id;
  #schema;
  /** 数据源自定义字段配置 */
  #fields = [];
  /** 数据源自定义方法配置 */
  #methods = [];
  constructor(options) {
    super();
    this.#id = options.schema.id;
    this.#schema = options.schema;
    this.app = options.app;
    this.setFields(options.schema.fields);
    this.setMethods(options.schema.methods || []);
    if (this.app.platform === "editor") {
      this.mockData = options.schema.mocks?.find((mock) => mock.useInEditor)?.data || this.getDefaultData();
      this.setData(this.mockData);
    } else if (typeof options.useMock === "boolean" && options.useMock) {
      this.mockData = options.schema.mocks?.find((mock) => mock.enable)?.data || this.getDefaultData();
      this.setData(this.mockData);
    } else if (!options.initialData) {
      this.setData(this.getDefaultData());
    } else {
      this.setData(options.initialData);
      this.isInit = true;
    }
  }
  get id() {
    return this.#id;
  }
  get type() {
    return this.#type;
  }
  get schema() {
    return this.#schema;
  }
  get fields() {
    return this.#fields;
  }
  get methods() {
    return this.#methods;
  }
  setFields(fields) {
    this.#fields = fields;
  }
  setMethods(methods) {
    this.#methods = methods;
  }
  setData(data, path) {
    if (path) {
      setValueByKeyPath(path, data, this.data);
    } else {
      this.data = data;
    }
    const changeEvent = {
      updateData: data,
      path
    };
    this.emit("change", changeEvent);
  }
  getDefaultData() {
    return getDefaultValueFromFields(this.#fields);
  }
  async init() {
    this.isInit = true;
  }
  destroy() {
    this.data = {};
    this.#fields = [];
    this.removeAllListeners();
  }
}

const urlencoded = (data) => Object.entries(data).reduce((prev, [key, value]) => {
  let v = value;
  if (typeof value === "object") {
    v = JSON.stringify(value);
  }
  if (typeof value !== "undefined") {
    return `${prev}${prev ? "&" : ""}${globalThis.encodeURIComponent(key)}=${globalThis.encodeURIComponent(`${v}`)}`;
  }
  return prev;
}, "");
const webRequest = async (options) => {
  const { url, method = "GET", headers = {}, params = {}, data = {}, ...config } = options;
  const query = urlencoded(params);
  let body = JSON.stringify(data);
  if (headers["Content-Type"]?.includes("application/x-www-form-urlencoded")) {
    body = urlencoded(data);
  }
  const response = await globalThis.fetch(query ? `${url}?${query}` : url, {
    method,
    headers,
    body: method === "GET" ? void 0 : body,
    ...config
  });
  return response.json();
};
class HttpDataSource extends DataSource {
  /** 是否正在发起请求 */
  isLoading = false;
  error;
  /** 请求配置 */
  httpOptions;
  /** 请求函数 */
  #fetch;
  /** 请求前需要执行的函数队列 */
  #beforeRequest = [];
  /** 请求后需要执行的函数队列 */
  #afterRequest = [];
  #type = "http";
  constructor(options) {
    const { options: httpOptions } = options.schema;
    super(options);
    this.httpOptions = httpOptions;
    if (typeof options.request === "function") {
      this.#fetch = options.request;
    } else if (typeof globalThis.fetch === "function") {
      this.#fetch = webRequest;
    }
    this.methods.forEach((method) => {
      if (typeof method.content !== "function")
        return;
      if (method.timing === "beforeRequest") {
        this.#beforeRequest.push(method.content);
      }
      if (method.timing === "afterRequest") {
        this.#afterRequest.push(method.content);
      }
    });
  }
  get type() {
    return this.#type;
  }
  async init() {
    if (this.schema.autoFetch) {
      await this.request(this.httpOptions);
    }
    super.init();
  }
  async request(options = {}) {
    this.isLoading = true;
    let reqOptions = {
      ...this.httpOptions,
      ...options
    };
    try {
      for (const method of this.#beforeRequest) {
        await method({ options: reqOptions, params: {}, dataSource: this, app: this.app });
      }
      if (typeof this.schema.beforeRequest === "function") {
        reqOptions = this.schema.beforeRequest(reqOptions, { app: this.app, dataSource: this });
      }
      let res = this.mockData ? this.mockData : await this.#fetch?.(reqOptions);
      for (const method of this.#afterRequest) {
        await method({ res, options: reqOptions, params: {}, dataSource: this, app: this.app });
      }
      if (typeof this.schema.afterResponse === "function") {
        res = this.schema.afterResponse(res, { app: this.app, dataSource: this, options: reqOptions });
      }
      if (this.schema.responseOptions?.dataPath) {
        const data = getValueByKeyPath(this.schema.responseOptions.dataPath, res);
        this.setData(data);
      } else {
        this.setData(res);
      }
      this.error = void 0;
    } catch (error) {
      this.error = {
        msg: error.message
      };
      this.emit("error", error);
    }
    this.isLoading = false;
  }
  get(options) {
    return this.request({
      ...options,
      method: "GET"
    });
  }
  post(options) {
    return this.request({
      ...options,
      method: "POST"
    });
  }
}

class DataSourceManager extends EventEmitter {
  static dataSourceClassMap = /* @__PURE__ */ new Map();
  static register(type, dataSource) {
    DataSourceManager.dataSourceClassMap.set(type, dataSource);
  }
  /**
   * @deprecated
   */
  static registe(type, dataSource) {
    DataSourceManager.register(type, dataSource);
  }
  static getDataSourceClass(type) {
    return DataSourceManager.dataSourceClassMap.get(type);
  }
  app;
  dataSourceMap = /* @__PURE__ */ new Map();
  data = {};
  useMock = false;
  constructor({ app, useMock, initialData }) {
    super();
    this.app = app;
    this.useMock = useMock;
    if (initialData) {
      this.data = initialData;
    }
    app.dsl?.dataSources?.forEach((config) => {
      this.addDataSource(config);
    });
    Promise.all(Array.from(this.dataSourceMap).map(async ([, ds]) => this.init(ds)));
  }
  async init(ds) {
    if (ds.isInit) {
      return;
    }
    if (this.app.jsEngine && ds.schema.disabledInitInJsEngine?.includes(this.app.jsEngine)) {
      return;
    }
    const beforeInit = [];
    const afterInit = [];
    ds.methods.forEach((method) => {
      if (typeof method.content !== "function")
        return;
      if (method.timing === "beforeInit") {
        beforeInit.push(method.content);
      }
      if (method.timing === "afterInit") {
        afterInit.push(method.content);
      }
    });
    for (const method of beforeInit) {
      await method({ params: {}, dataSource: ds, app: this.app });
    }
    await ds.init();
    for (const method of afterInit) {
      await method({ params: {}, dataSource: ds, app: this.app });
    }
  }
  get(id) {
    return this.dataSourceMap.get(id);
  }
  async addDataSource(config) {
    if (!config)
      return;
    const DataSourceClass = DataSourceManager.dataSourceClassMap.get(config.type) || DataSource;
    const ds = new DataSourceClass({
      app: this.app,
      schema: config,
      request: this.app.request,
      useMock: this.useMock,
      initialData: this.data[config.id]
    });
    this.dataSourceMap.set(config.id, ds);
    this.data[ds.id] = ds.data;
    ds.on("change", (changeEvent) => {
      this.setData(ds, changeEvent);
    });
  }
  setData(ds, changeEvent) {
    this.data[ds.id] = ds.data;
    this.emit("change", ds.id, changeEvent);
  }
  removeDataSource(id) {
    this.get(id)?.destroy();
    delete this.data[id];
    this.dataSourceMap.delete(id);
  }
  updateSchema(schemas) {
    schemas.forEach((schema) => {
      const ds = this.get(schema.id);
      if (!ds) {
        return;
      }
      this.removeDataSource(schema.id);
      this.addDataSource(schema);
      const newDs = this.get(schema.id);
      if (newDs) {
        this.init(newDs);
      }
    });
  }
  compiledNode(node, sourceId) {
    if (node.condResult === false)
      return node;
    if (node.visible === false)
      return node;
    return compiledNode(
      (value) => {
        if (typeof value === "string") {
          return template(value)(this.data);
        }
        if (value?.isBindDataSource && value.dataSourceId) {
          return this.data[value.dataSourceId];
        }
        if (value?.isBindDataSourceField && value.dataSourceId && typeof value.template === "string") {
          return template(value.template)(this.data[value.dataSourceId]);
        }
        if (Array.isArray(value) && typeof value[0] === "string") {
          const [prefixId, ...fields] = value;
          const prefixIndex = prefixId.indexOf(DATA_SOURCE_FIELDS_SELECT_VALUE_PREFIX);
          if (prefixIndex > -1) {
            const dsId = prefixId.substring(prefixIndex + DATA_SOURCE_FIELDS_SELECT_VALUE_PREFIX.length);
            const data = this.data[dsId];
            if (!data)
              return value;
            return fields.reduce((accumulator, currentValue) => {
              if (Array.isArray(accumulator))
                return accumulator;
              if (isObject(accumulator)) {
                return accumulator[currentValue];
              }
              return "";
            }, data);
          }
        }
        return value;
      },
      cloneDeep(node),
      this.app.dsl?.dataSourceDeps || {},
      sourceId
    );
  }
  compliedConds(node) {
    if (!node.displayConds || !Array.isArray(node.displayConds) || !node.displayConds.length)
      return true;
    for (const { cond } of node.displayConds) {
      if (!cond)
        continue;
      let result = true;
      for (const { op, value, range, field } of cond) {
        const [sourceId, fieldKey] = field;
        const dsData = this.data[sourceId];
        if (!dsData || !fieldKey) {
          break;
        }
        const fieldValue = dsData[fieldKey];
        if (!compiledCond(op, fieldValue, value, range)) {
          result = false;
          break;
        }
      }
      if (result) {
        return true;
      }
    }
    return false;
  }
  destroy() {
    this.removeAllListeners();
    this.data = {};
    this.dataSourceMap.forEach((ds) => {
      ds.destroy();
    });
    this.dataSourceMap.clear();
  }
}
DataSourceManager.register("http", HttpDataSource);
const DataSourceManager$1 = DataSourceManager;

const createDataSourceManager = (app, useMock, initialData) => {
  const { dsl, platform } = app;
  if (!dsl?.dataSources)
    return;
  const dataSourceManager = new DataSourceManager$1({ app, useMock, initialData });
  if (dsl.dataSources && dsl.dataSourceCondDeps && platform !== "editor") {
    getNodes(getDepNodeIds(dsl.dataSourceCondDeps), dsl.items).forEach((node) => {
      node.condResult = dataSourceManager.compliedConds(node);
      replaceChildNode(node, dsl.items);
    });
  }
  if (dsl.dataSources && dsl.dataSourceDeps) {
    getNodes(getDepNodeIds(dsl.dataSourceDeps), dsl.items).forEach((node) => {
      replaceChildNode(dataSourceManager.compiledNode(node), dsl.items);
    });
  }
  if (app.jsEngine !== "nodejs") {
    dataSourceManager.on("change", (sourceId, changeEvent) => {
      const dep = dsl.dataSourceDeps?.[sourceId] || {};
      const condDep = dsl.dataSourceCondDeps?.[sourceId] || {};
      const nodeIds = union([...Object.keys(condDep), ...Object.keys(dep)]);
      dataSourceManager.emit(
        "update-data",
        getNodes(nodeIds, dsl.items).map((node) => {
          const newNode = cloneDeep(node);
          newNode.condResult = dataSourceManager.compliedConds(newNode);
          return dataSourceManager.compiledNode(newNode);
        }),
        sourceId,
        changeEvent
      );
    });
  }
  return dataSourceManager;
};

export { DataSource, DataSourceManager$1 as DataSourceManager, HttpDataSource, createDataSourceManager };
