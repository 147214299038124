/*
 * Tencent is pleased to support the open source community by making TMagicEditor available.
 *
 * Copyright (C) 2023 THL A29 Limited, a Tencent company.  All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createApp, defineAsyncComponent } from 'vue';

import Core from '@tmagic/core';
import { DataSourceManager } from '@tmagic/data-source';
import { getUrlParam } from '@tmagic/utils';
import { ElMessage } from 'element-plus';

import './page-config.ts';//数据文件 window.magicDSL
import components from '../.tmagic/async-comp-entry';
import datasources from '../.tmagic/datasource-entry';
import plugins from '../.tmagic/plugin-entry';

import request from './utils/request';
import http from './utils/http';
import AppComponent from './App.vue';
import { getLocalConfig } from './utils';
import { pcStyle, Cookie } from './utils/common';

import { sha256 } from "js-sha256";
import { Fn_Guid, GetSpecialCustomConfig, GetPixelConfig } from "./utils/overseas_common";

import '@tmagic/utils/resetcss.css';
import 'element-plus/dist/index.css';


declare global {  
  interface Window {  
    magicDSL: any; // 替换为 Config 的实际类型  
  }  
}

const magicApp = createApp(AppComponent);

magicApp.use(request);

Object.entries(components).forEach(([type, component]: [string, any]) => {
  magicApp.component(`magic-ui-${type}`, defineAsyncComponent(component));
});

Object.entries(datasources).forEach(([type, ds]: [string, any]) => {
  DataSourceManager.register(type, ds);
});

Object.values(plugins).forEach((plugin: any) => {
  magicApp.use(plugin);
});


// 线上初始数据
const getInit = async (specialId: string) => {
  const res: any = await http.request({
    method: "GET",
    url: `/api/special/v1/info/${specialId}`,
  });
  
  if (res.code === 1) {
    let item = res.data.custom_data;
    window.magicDSL = [item]
    window.Config = {
      specialInfo: res.data
    }


    if(JSON.stringify(item) == "{}"){
      ElMessage({message: "专题数据为空",type: 'error', duration: 2000});
      return false;
    }
    // initFbSDK();

    
    //tkd设置
    try{
      document.title = res.data.title;
      (document as any).querySelector('meta[name="keywords"]').setAttribute("content", res.data.keyword);
      (document as any).querySelector('meta[name="description"]').setAttribute("content", res.data.description);
    }catch(e){
      console.log(e);
    }

    const pixel_data = item?.items[0]?.pixel || '';
    console.log('pixel_data:', pixel_data);
    if(pixel_data){
      // fb域名验证
      if(pixel_data.fb_domain_verification) {
        initFbDomainVerification(pixel_data.fb_domain_verification);
      }
      // fb像素代码
      if(pixel_data.fb_pixel_id) {
        try {
          // 暂时统一用fb_external_id，如有需求每个事件都走不同id再改。
          const c_fb_external_id = Cookie().get('fb_external_id');
          if(!c_fb_external_id && c_fb_external_id !== null && c_fb_external_id !==''){
            const fb_external_id = Fn_Guid();
            Cookie().set('fb_external_id', fb_external_id);
          }
          let custom_events_id_map:any = {
            PageView: c_fb_external_id, //Fn_Guid(),
            Purchase: c_fb_external_id, //Fn_Guid(),
          };
          const custom_data = GetSpecialCustomConfig();
          custom_data.items[0].items.forEach((item:any) => {
            const item_keys = Object.keys(item);
            const filter_keys = item_keys.filter(fitem => fitem.startsWith('custom_event_'));
            filter_keys.forEach(citem=>{
              // const _k = citem.replace('custom_event_','');
              const _k = item[citem];
              if(_k){
                custom_events_id_map[_k] = c_fb_external_id; //Fn_Guid();
              }
            });
          });
          console.log('custom_events_id_map:', custom_events_id_map);
          (window as any).custom_events_id_map = custom_events_id_map;
        } catch (error) {
          console.log('fb_pixel err', error);
        }

        initFbPixelSdk(pixel_data.fb_pixel_id);
      }
      // gg像素ga4代码
      if(pixel_data.gg_ga4_pixel_id) {
        initGooglePixelSdk(pixel_data.gg_ga4_pixel_id);
      }
      // gg广告像素代码
      if(pixel_data.gg_ad_pixel_id) {
        initGooglePixelSdk(pixel_data.gg_ad_pixel_id);
      }
      // tiktok像素代码
      if(pixel_data.tiktok_pixel_id) {
        initTiktokPixelSdk(pixel_data.tiktok_pixel_id);
      }
      // twitter像素代码
      if(pixel_data.twitter_pixel_id) {
        initTwitterPixelSdk(pixel_data.twitter_pixel_id);
      }
    }


    // try {
    //   const script = document.createElement("script");
    //   script.src = "https://accounts.google.com/gsi/client";
    //   script.async = true;
    //   script.onload = () => {
    //   };
    //   document.body.appendChild(script);
    // } catch (err) {
    //   console.log("init Sdk:", err);
    // }



    report(res.data);
    buildApp(window.magicDSL);
  } else if(res.code === 99999) {
  }else{
    ElMessage({message: res.message,type: 'error', duration: 2000});
  }
}

// facebook domain verification
const initFbDomainVerification = (content) => {
  const meta = document.createElement('meta');
  meta.name= "facebook-domain-verification";
  meta.content = content;
  document.head.appendChild(meta);
}

// facebook pixel sdk
const initFbPixelSdk = (pixel_id: string) => {
  // noscript 备用加载
  function initSdkBak() {
    try {
      const imgElement = document.createElement("img");
      imgElement.setAttribute('height', '1');
      imgElement.setAttribute('width', '1');
      imgElement.setAttribute('style', 'display:none');
      imgElement.setAttribute('src', `https://www.facebook.com/tr?id=${pixel_id}&ev=PageView&noscript=1`);
      const noscriptElement = document.createElement("noscript");
      noscriptElement.onload = () => {
      };
      noscriptElement.appendChild(imgElement);
      document.body.appendChild(noscriptElement);
    } catch (err) {
      console.log("init fb pixel sdk noscript err:", err);
    }
  }
  // 正常加载
  function initSdk() {
    try {
      // const script = document.createElement("script");
      // script.src = "https://connect.facebook.net/en_US/fbevents.js";
      // script.async = true;
      // script.onload = () => {
      //   fbq('init', pixel_id);
      //   fbq('track', 'PageView');
      // };
      // document.head.appendChild(script);
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
      try {
        // const pixel_data = GetPixelConfig();
        // if(pixel_data && pixel_data?.fb_is_api_conversion === '1') {
          const fb_external_id = Cookie().get('fb_external_id');
          const sha256_fb_external_id = sha256(fb_external_id);
          console.log('fb_external_id:', fb_external_id);
          console.log('sha256_fb_external_id:', sha256_fb_external_id);
          fbq("init", pixel_id, {external_id : sha256_fb_external_id});
          fbq("track", "PageView", { eventID: (window as any).custom_events_id_map?.['PageView'] || '' });
        // }else {
        //   fbq("init", pixel_id);
        //   fbq("track", "PageView");
        // }
      } catch (error) {
        initSdkBak();
      }
    } catch (err) {
      console.log("init fb pixel sdk err:", err);
      initSdkBak();
    }
  }
  initSdk();
}

const initFbSDK = () => {
  if (!document.getElementById("fb-root")) {
    try {
      const dom = document.createElement("div");
      dom.id = `fb-root`;
      document.body.appendChild(dom);
    } catch (err) {
      console.log("init fb-root err:", err);
    }
  }
  if (typeof (FB as any) === "undefined") {
    console.log("Facebook SDK 未加载过");
    try {
      const script = document.createElement("script");
      script.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=16.0&nonce="FOKrbAYI`;
      // script.src = `https://connect.facebook.net/en_US/all.js`;
      script.async = true;
      script.defer = true;
      script.crossOrigin = "anonymous";
      script.onload = () => {};
      document.body.appendChild(script);
    } catch (err) {
      console.log("init Facebook sdk err:", err);
    }
  } else {
    console.log("Facebook SDK 已加载过");
  }
}


// google pixel sdk
const initGooglePixelSdk = (pixel_id: string) => {
  try {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${pixel_id}`;
    script.async = true;
    script.onload = () => {
      (window as any).dataLayer = (window as any).dataLayer || [];
      // function gtag(){
      //   (window as any).push(arguments);
      // }
      //  gtag('js', new Date());
      //  gtag('config', pixel_id);
        (window as any).gtag = function () {
        (window as any).dataLayer.push(arguments);
      };
      (window as any).gtag("js", new Date());
      (window as any).gtag("config", pixel_id);
    };
    document.head.appendChild(script);
  } catch (err) {
    console.log("init google pixel sdk err:", err);
  }
}

// tiktok pixel sdk
const initTiktokPixelSdk = (pixel_id: string) => {
    try {
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
        ttq.load(pixel_id);
        ttq.page();
      }(window, document, 'ttq');
    } catch (err) {
      console.log("init tiktok pixel sdk err:", err);
    }
}

// twitter pixel sdk
const initTwitterPixelSdk = (pixel_id: string) => {
  try {
    !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
    },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
    a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
    twq('config', pixel_id);
  } catch (err) {
    console.log("init twitter pixel sdk err:", err);
  }
}


// 上报
const report = async (data: any) => {
  const res: any = await http.request({
    method: "POST",
    url: `/api/special/v1/report`,
    data: {
      common: {
        eid: "visit",
        business_type: 'marketing',
        division: JSON.stringify(data.company_id),
      },
      report: {
        activity_id: JSON.stringify(data.id),
        activity_name: data.name,
        activity_tag: data.short_path,
        channel: getUrlParam('referer'),
        game_id: data.config.game_id ? JSON.stringify(data.config.game_id): "",
        region_id: data.config.server_id ? JSON.stringify(data.config.server_id) : "",
        start_time: parseInt(new Date(data.start_at).getTime()/1000),
        end_time: parseInt(new Date(data.end_at).getTime()/1000),
        domain: window.location.origin,
        event_time: parseInt(new Date().getTime()/1000)
      }
    }
  });
}

// 线上初始数据
const buildApp = async (dsl: any) => {
  const app = new Core({
    ua: window.navigator.userAgent,
    config: ((dsl) || [])[0] || {},
    curPage: getUrlParam('page'),
    useMock: Boolean(getUrlParam('useMock')),
  });
  

  // web兼容样式
  if(getUrlParam('isSkin')){
    app.setDesignWidth(app.env.isWeb ? window.document.documentElement.getBoundingClientRect().width : 375);
  }else if(pcStyle(app) == "pc"){
    // app.setDesignWidth(800);
    document.documentElement.style.fontSize = `220px`;
  }else{
    app.setDesignWidth(app.env.isWeb ? window.document.documentElement.getBoundingClientRect().width : 375);
  }
  
  
  magicApp.config.globalProperties.app = app;
  magicApp.provide('app', app);
  
  magicApp.mount('#app');
}

// 初始化
(()=>{
  if(getUrlParam('isTest')){
    getInit(getUrlParam('isTest'))
  }else if(getUrlParam('localPreview')){
    buildApp(getLocalConfig())
  }else{
    getInit(window.location.pathname.replaceAll("/",""));
  }
})();

// try {
//   Sentry.init({
//     dsn: "https://17a77bd9294f279114d3b7aab86a59bc@sentry.qe23.com/8",
//     integrations: [
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// } catch (error) {
//   console.log(error)
// }

