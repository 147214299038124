import { EventEmitter } from 'events';
import { isEmpty, has } from 'lodash-es';
import { createDataSourceManager } from '@tmagic/data-source';
import { HookType, ActionType } from '@tmagic/schema';

class Env {
  isIos = false;
  isIphone = false;
  isIpad = false;
  isAndroid = false;
  isAndroidPad = false;
  isMac = false;
  isWin = false;
  isMqq = false;
  isWechat = false;
  isWeb = false;
  constructor(ua = globalThis.navigator.userAgent, options = {}) {
    this.isIphone = ua.indexOf("iPhone") >= 0;
    this.isIpad = /(iPad).*OS\s([\d_]+)/.test(ua);
    this.isIos = this.isIphone || this.isIpad;
    this.isAndroid = ua.indexOf("Android") >= 0;
    this.isAndroidPad = this.isAndroid && ua.indexOf("Mobile") < 0;
    this.isMac = ua.indexOf("Macintosh") >= 0;
    this.isWin = ua.indexOf("Windows") >= 0;
    this.isMqq = /QQ\/([\d.]+)/.test(ua);
    this.isWechat = ua.indexOf("MicroMessenger") >= 0 && ua.indexOf("wxwork") < 0;
    this.isWeb = !this.isIos && !this.isAndroid && !/(WebOS|BlackBerry)/.test(ua);
    Object.entries(options).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}
const Env$1 = Env;

const COMMON_EVENT_PREFIX = "magic:common:events:";
const COMMON_METHOD_PREFIX = "magic:common:actions:";
const CommonMethod = {
  SHOW: "show",
  HIDE: "hide",
  SCROLL_TO_VIEW: "scrollIntoView",
  SCROLL_TO_TOP: "scrollToTop"
};
const DEFAULT_EVENTS = [{ label: "点击", value: `${COMMON_EVENT_PREFIX}click` }];
const DEFAULT_METHODS = [];
const getCommonEventName = (commonEventName) => {
  if (commonEventName.startsWith(COMMON_EVENT_PREFIX))
    return commonEventName;
  return `${COMMON_EVENT_PREFIX}${commonEventName}`;
};
const isCommonMethod = (methodName) => methodName.startsWith(COMMON_METHOD_PREFIX);
const getDirectComponent = (element, app) => {
  if (!element) {
    return false;
  }
  if (!element.id) {
    return getDirectComponent(element.parentElement, app);
  }
  const node = app.page?.getNode(element.id);
  if (!node) {
    return false;
  }
  return node;
};
const commonClickEventHandler = (app, eventName, e) => {
  const node = getDirectComponent(e.target, app);
  if (node) {
    app.emit(getCommonEventName(eventName), node);
  }
};
const bindCommonEventListener = (app) => {
  if (app.jsEngine !== "browser")
    return;
  window.document.body.addEventListener("click", (e) => {
    commonClickEventHandler(app, "click", e);
  });
  window.document.body.addEventListener(
    "click",
    (e) => {
      commonClickEventHandler(app, "click:capture", e);
    },
    true
  );
};
const triggerCommonMethod = (methodName, node) => {
  const { instance } = node;
  if (!instance)
    return;
  switch (methodName.replace(COMMON_METHOD_PREFIX, "")) {
    case CommonMethod.SHOW:
      instance.show();
      break;
    case CommonMethod.HIDE:
      instance.hide();
      break;
    case CommonMethod.SCROLL_TO_VIEW:
      instance.$el?.scrollIntoView({ behavior: "smooth" });
      break;
    case CommonMethod.SCROLL_TO_TOP:
      window.scrollTo({ top: 0, behavior: "smooth" });
      break;
  }
};

class Store {
  data = {};
  set(key, value) {
    this.data[key] = value;
  }
  get(key) {
    return this.data[key];
  }
}

class Node extends EventEmitter {
  data;
  style;
  events;
  instance;
  page;
  parent;
  app;
  store = new Store();
  constructor(options) {
    super();
    this.page = options.page;
    this.parent = options.parent;
    this.app = options.app;
    const { events } = options.config;
    this.data = options.config;
    this.events = events || [];
    this.listenLifeSafe();
  }
  setData(data) {
    this.data = data;
    this.emit("update-data");
  }
  destroy() {
    this.removeAllListeners();
  }
  listenLifeSafe() {
    this.once("created", async (instance) => {
      this.once("destroy", () => {
        this.instance = null;
        if (typeof this.data.destroy === "function") {
          this.data.destroy(this);
        }
        this.listenLifeSafe();
      });
      this.instance = instance;
      await this.runCodeBlock("created");
    });
    this.once("mounted", async (instance) => {
      this.instance = instance;
      const eventConfigQueue = this.app.eventQueueMap[instance.config.id] || [];
      for (let eventConfig = eventConfigQueue.shift(); eventConfig; eventConfig = eventConfigQueue.shift()) {
        this.app.compActionHandler(eventConfig.eventConfig, eventConfig.fromCpt, eventConfig.args);
      }
      await this.runCodeBlock("mounted");
    });
  }
  async runCodeBlock(hook) {
    if (typeof this.data[hook] === "function") {
      await this.data[hook](this);
      return;
    }
    if (this.data[hook]?.hookType !== HookType.CODE || isEmpty(this.app.codeDsl))
      return;
    for (const item of this.data[hook].hookData) {
      const { codeId, params = {} } = item;
      const functionContent = this.app.codeDsl?.[codeId]?.content;
      if (typeof functionContent === "function") {
        await functionContent({ app: this.app, params });
      }
    }
  }
}
const Node$1 = Node;

class Page extends Node$1 {
  nodes = /* @__PURE__ */ new Map();
  constructor(options) {
    super(options);
    this.setNode(options.config.id, this);
    this.initNode(options.config, this);
  }
  initNode(config, parent) {
    const node = new Node$1({
      config,
      parent,
      page: this,
      app: this.app
    });
    this.setNode(config.id, node);
    if (config.type === "page-fragment-container" && config.pageFragmentId) {
      const pageFragment = this.app.dsl?.items?.find((page) => page.id === config.pageFragmentId);
      if (pageFragment) {
        config.items = [pageFragment];
      }
    }
    config.items?.forEach((element) => {
      this.initNode(element, node);
    });
  }
  getNode(id) {
    return this.nodes.get(id);
  }
  setNode(id, node) {
    this.nodes.set(id, node);
  }
  deleteNode(id) {
    this.nodes.delete(id);
  }
  destroy() {
    super.destroy();
    this.nodes.clear();
  }
}
const Page$1 = Page;

const style2Obj = (style) => {
  if (typeof style !== "string") {
    return style;
  }
  const obj = {};
  style.split(";").forEach((element) => {
    if (!element) {
      return;
    }
    const items = element.split(":");
    let key = items.shift();
    let value = items.join(":");
    if (!key)
      return;
    key = key.replace(/^\s*/, "").replace(/\s*$/, "");
    value = value.replace(/^\s*/, "").replace(/\s*$/, "");
    key = key.split("-").map((v, i) => i > 0 ? `${v[0].toUpperCase()}${v.substr(1)}` : v).join("");
    obj[key] = value;
  });
  return obj;
};
const fillBackgroundImage = (value) => {
  if (value && !/^url/.test(value) && !/^linear-gradient/.test(value)) {
    return `url(${value})`;
  }
  return value;
};
const isNumber = (value) => /^(-?\d+)(\.\d+)?$/.test(value);

class App extends EventEmitter {
  env = new Env$1();
  dsl;
  codeDsl;
  dataSourceManager;
  page;
  useMock = false;
  platform = "mobile";
  jsEngine = "browser";
  designWidth = 375;
  request;
  components = /* @__PURE__ */ new Map();
  eventQueueMap = {};
  eventList = /* @__PURE__ */ new Map();
  constructor(options) {
    super();
    this.setEnv(options.ua);
    this.codeDsl = options.config?.codeBlocks;
    options.platform && (this.platform = options.platform);
    options.jsEngine && (this.jsEngine = options.jsEngine);
    if (typeof options.useMock === "boolean") {
      this.useMock = options.useMock;
    }
    if (typeof options.designWidth !== "undefined") {
      this.setDesignWidth(options.designWidth);
    }
    if (options.transformStyle) {
      this.transformStyle = options.transformStyle;
    }
    if (options.request) {
      this.request = options.request;
    }
    if (options.config) {
      this.setConfig(options.config, options.curPage);
    }
    bindCommonEventListener(this);
  }
  setEnv(ua) {
    this.env = new Env$1(ua);
  }
  setDesignWidth(width) {
    this.designWidth = width;
    if (this.jsEngine === "browser") {
      this.calcFontsize();
      globalThis.removeEventListener("resize", this.calcFontsize);
      globalThis.addEventListener("resize", this.calcFontsize);
    }
  }
  /**
   * 将dsl中的style配置转换成css，主要是将数值转成rem为单位的样式值，例如100将被转换成1rem
   * @param style Object
   * @returns Object
   */
  transformStyle(style) {
    if (!style) {
      return {};
    }
    let styleObj = {};
    const results = {};
    if (typeof style === "string") {
      styleObj = style2Obj(style);
    } else {
      styleObj = { ...style };
    }
    const isHippy = this.jsEngine === "hippy";
    const whiteList = ["zIndex", "opacity", "fontWeight"];
    Object.entries(styleObj).forEach(([key, value]) => {
      if (key === "scale" && !results.transform && isHippy) {
        results.transform = [{ scale: value }];
      } else if (key === "backgroundImage" && !isHippy) {
        value && (results[key] = fillBackgroundImage(value));
      } else if (key === "transform" && typeof value !== "string") {
        results[key] = this.getTransform(value);
      } else if (!whiteList.includes(key) && value && /^[-]?[0-9]*[.]?[0-9]*$/.test(value)) {
        results[key] = isHippy ? value : `${value / 100}rem`;
      } else {
        results[key] = value;
      }
    });
    return results;
  }
  /**
   * 设置dsl
   * @param config dsl跟节点
   * @param curPage 当前页面id
   */
  setConfig(config, curPage) {
    this.dsl = config;
    if (!curPage && config.items.length) {
      curPage = config.items[0].id;
    }
    if (this.dataSourceManager) {
      this.dataSourceManager.destroy();
    }
    this.dataSourceManager = createDataSourceManager(this, this.useMock);
    this.codeDsl = config.codeBlocks;
    this.setPage(curPage || this.page?.data?.id);
  }
  /**
   * 留着为了兼容，不让报错
   * @deprecated
   */
  addPage() {
    console.info("addPage 已经弃用");
  }
  setPage(id) {
    const pageConfig = this.dsl?.items.find((page) => `${page.id}` === `${id}`);
    if (!pageConfig) {
      if (this.page) {
        this.page.destroy();
        this.page = void 0;
      }
      super.emit("page-change");
      return;
    }
    if (pageConfig === this.page?.data)
      return;
    if (this.page) {
      this.page.destroy();
    }
    this.page = new Page$1({
      config: pageConfig,
      app: this
    });
    super.emit("page-change", this.page);
    this.bindEvents();
  }
  deletePage() {
    this.page = void 0;
  }
  /**
   * 兼容id参数
   * @param id 节点id
   * @returns Page | void
   */
  getPage(id) {
    if (!id)
      return this.page;
    if (this.page && `${this.page.data.id}` === `${id}`) {
      return this.page;
    }
  }
  registerComponent(type, Component) {
    this.components.set(type, Component);
  }
  unregisterComponent(type) {
    this.components.delete(type);
  }
  resolveComponent(type) {
    return this.components.get(type);
  }
  bindEvents() {
    Array.from(this.eventList.keys()).forEach((handler) => {
      const name = this.eventList.get(handler);
      name && this.off(name, handler);
    });
    this.eventList.clear();
    if (!this.page)
      return;
    for (const [, value] of this.page.nodes) {
      value.events?.forEach((event) => {
        const eventName = `${event.name}_${value.data.id}`;
        const eventHandler = (fromCpt, ...args) => {
          this.eventHandler(event, fromCpt, args);
        };
        this.eventList.set(eventHandler, eventName);
        this.on(eventName, eventHandler);
      });
    }
  }
  emit(name, ...args) {
    const [node, ...otherArgs] = args;
    if (node instanceof Node$1 && node?.data?.id) {
      return super.emit(`${String(name)}_${node.data.id}`, node, ...otherArgs);
    }
    return super.emit(name, ...args);
  }
  /**
   * 执行代码块动作
   * @param eventConfig 代码动作的配置
   * @returns void
   */
  async codeActionHandler(eventConfig, args) {
    const { codeId = "", params = {} } = eventConfig;
    if (!codeId || isEmpty(this.codeDsl))
      return;
    if (this.codeDsl[codeId] && typeof this.codeDsl[codeId]?.content === "function") {
      await this.codeDsl[codeId].content({ app: this, params, eventParams: args });
    }
  }
  /**
   * 执行联动组件动作
   * @param eventConfig 联动组件的配置
   * @returns void
   */
  async compActionHandler(eventConfig, fromCpt, args) {
    if (!this.page)
      throw new Error("当前没有页面");
    const { method: methodName, to } = eventConfig;
    const toNode = this.page.getNode(to);
    if (!toNode)
      throw `ID为${to}的组件不存在`;
    if (isCommonMethod(methodName)) {
      return triggerCommonMethod(methodName, toNode);
    }
    if (toNode.instance) {
      if (typeof toNode.instance[methodName] === "function") {
        await toNode.instance[methodName](fromCpt, ...args);
      }
    } else {
      this.addEventToMap({
        eventConfig,
        fromCpt,
        args
      });
    }
  }
  async dataSourceActionHandler(eventConfig, args) {
    const { dataSourceMethod = [], params = {} } = eventConfig;
    const [id, methodName] = dataSourceMethod;
    if (!id || !methodName)
      return;
    const dataSource = this.dataSourceManager?.get(id);
    if (!dataSource)
      return;
    const methods = dataSource.methods || [];
    const method = methods.find((item) => item.name === methodName);
    if (!method)
      return;
    if (typeof method.content === "function") {
      await method.content({ app: this, params, dataSource, eventParams: args });
    }
  }
  destroy() {
    this.removeAllListeners();
    this.page = void 0;
    if (this.jsEngine === "browser") {
      globalThis.removeEventListener("resize", this.calcFontsize);
    }
  }
  /**
   * 事件联动处理函数
   * @param eventConfig 事件配置
   * @param fromCpt 触发事件的组件
   * @param args 事件参数
   */
  async eventHandler(eventConfig, fromCpt, args) {
    if (has(eventConfig, "actions")) {
      const { actions } = eventConfig;
      for (const actionItem of actions) {
        if (actionItem.actionType === ActionType.COMP) {
          await this.compActionHandler(actionItem, fromCpt, args);
        } else if (actionItem.actionType === ActionType.CODE) {
          await this.codeActionHandler(actionItem, args);
        } else if (actionItem.actionType === ActionType.DATA_SOURCE) {
          await this.dataSourceActionHandler(actionItem, args);
        }
      }
    } else {
      await this.compActionHandler(eventConfig, fromCpt, args);
    }
  }
  addEventToMap(event) {
    if (this.eventQueueMap[event.eventConfig.to]) {
      this.eventQueueMap[event.eventConfig.to].push(event);
    } else {
      this.eventQueueMap[event.eventConfig.to] = [event];
    }
  }
  getTransform(value) {
    if (!value)
      return [];
    const transform = Object.entries(value).map(([transformKey, transformValue]) => {
      if (!transformValue.trim())
        return "";
      if (transformKey === "rotate" && isNumber(transformValue)) {
        transformValue = `${transformValue}deg`;
      }
      return this.jsEngine !== "hippy" ? `${transformKey}(${transformValue})` : { [transformKey]: transformValue };
    });
    if (this.jsEngine === "hippy") {
      return transform;
    }
    const values = transform.join(" ");
    return !values.trim() ? "none" : values;
  }
  calcFontsize() {
    const { width } = document.documentElement.getBoundingClientRect();
    const fontSize = width / (this.designWidth / 100);
    document.documentElement.style.fontSize = `${fontSize}px`;
  }
}
const App$1 = App;

export { DEFAULT_EVENTS, DEFAULT_METHODS, Env$1 as Env, Node$1 as Node, Page$1 as Page, bindCommonEventListener, App$1 as default, getCommonEventName, isCommonMethod, triggerCommonMethod };
