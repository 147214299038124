
interface Environment {  
  [key: string]: object; // 添加字符串索引签名  
}

let envConfig: Environment = {
  "localhost:8098": {
    platform: "w9377",
    platform_id: "11",
    api: "//special-api-dev.9377.com/",
    env: "development",
  },

  // "localhost:8098": {
  //   platform: "m9377",
  //   platform_id: "14",
  //   api: "//special-api-dev.9377.cn/",
  //   env: "development",
  // },
  // "localhost:8098": {
  //   platform: "chuangyue",
  //   platform_id: "29",
  //   api: "//special-api-dev.chuangyuejoy.com/",
  //   env: "development",
  // },
  // "localhost:8098": {
  //   platform: "mengw",
  //   platform_id: "17",
  //   api: "//special-api-dev.mengw.com/",
  //   env: "development",
  // },
  "localhost:5500": {
    platform: "mengw",
    platform_id: "17",
    api: "//special-api-dev.mengw.com/",
    env: "development",
  },
  //营销
  "special-editor-dev.qe23.com": {
    platform: "mengw",
    platform_id: "17",
    api: "//special-api-dev.mengw.com/",
    env: "development",
  },
  "special-editor-test.qe23.com": {
    platform: "mengw",
    platform_id: "17",
    api: "//special-api-test.mengw.com/",
    env: "test",
  },
  "special-editor.qe23.com": {
    platform: "mengw",
    api: "//special-api.mengw.com/",
    env: "production",
  },
  "special-editor-test.tapupup.com": {
    platform: "tapupup",
    platform_id: "21",
    api: "//special-api-test.tapupup.com/",
    env: "test",
  },
  "special-editor.tapupup.com": {
    platform: "tapupup",
    platform_id: "21",
    api: "//special-api.tapupup.com/",
    env: "production",
  },
  //mengw
  "special-pro-dev.mengw.com": {
    platform: "mengw",
    platform_id: "17",
    api: "//special-api-dev.mengw.com/",
    env: "development",
  },
  "special-pro-test.mengw.com": {
    platform: "mengw",
    platform_id: "17",
    api: "//special-api-test.mengw.com/",
    env: "test",
  },
  "special-pro.mengw.com": {
    platform: "mengw",
    platform_id: "17",
    api: "//special-api.mengw.com/",
    env: "production",
  },
  //m9377
  "special-pro-dev.9377.cn": {
    platform: "m9377",
    platform_id: "14",
    api: "//special-api-dev.9377.cn/",
    env: "development",
  },
  "special-pro-test.9377.cn": {
    platform: "m9377",
    platform_id: "14",
    api: "//special-api-test.9377.cn/",
    env: "test",
  },
  "special-pro.9377.cn": {
    platform: "m9377",
    platform_id: "14",
    api: "//special-api.9377.cn/",
    env: "production",
  },
  //chuangyuejoy
  "special-pro-dev.chuangyuejoy.com": {
    platform: "chuangyue",
    platform_id: "29",
    api: "//special-api-dev.chuangyuejoy.com/",
    env: "development",
  },
  "special-pro-test.chuangyuejoy.com": {
    platform: "chuangyue",
    platform_id: "29",
    api: "//special-api-test.chuangyuejoy.com/",
    env: "test",
  },
  "special-pro.chuangyuejoy.com": {
    platform: "chuangyue",
    platform_id: "29",
    api: "//special-api.chuangyuejoy.com/",
    env: "production",
  },
  //178hw
  "special-pro-dev.178hw.com": {
    platform: "huanwan",
    platform_id: "31",
    api: "//special-api-dev.178hw.com/",
    env: "development",
  },
  "special-pro-test.178hw.com": {
    platform: "huanwan",
    platform_id: "31",
    api: "//special-api-test.178hw.com/",
    env: "test",
  },
  "special-pro.178hw.com": {
    platform: "huanwan",
    platform_id: "31",
    api: "//special-api.178hw.com/",
    env: "production",
  },
  //9377页游
  "special-pro-dev.9377.com": {
    platform: "w9377",
    platform_id: "11",
    api: "//special-api-dev.9377.com/",
    env: "development",
  },
  "special-pro-test.9377.com": {
    platform: "w9377",
    platform_id: "11",
    api: "//special-api-test.9377.com/",
    env: "test",
  },
  "special-pro.9377.com": {
    platform: "w9377",
    platform_id: "11",
    api: "//special-api.9377.com/",
    env: "production",
  },
  //4366页游
  "special-pro-dev.4366.com": {
    platform: "w4366",
    platform_id: "8",
    api: "//special-api-dev.4366.com/",
    env: "development",
  },
  "special-pro-test.4366.com": {
    platform: "w4366",
    platform_id: "8",
    api: "//special-api-test.4366.com/",
    env: "test",
  },
  "special-pro.4366.com": {
    platform: "w4366",
    platform_id: "8",
    api: "//special-api.4366.com/",
    env: "production",
  },
  //2a
  "special-pro-test.2a.com": {
    platform: "2a",
    platform_id: "22",
    api: "//special-api-test.2a.com/",
    env: "test",
  },
  "special-pro.2a.com": {
    platform: "2a",
    platform_id: "22",
    api: "//special-api.2a.com/",
    env: "production",
  },
  //tapupup
  "special-pro-test.tapupup.com": {
    platform: "tapupup",
    platform_id: "21",
    api: "//special-api-test.tapupup.com/",
    env: "test",
  },
  "special-pro.tapupup.com": {
    platform: "tapupup",
    platform_id: "21",
    api: "//special-api.tapupup.com/",
    env: "production",
  },
  "kr.theacebanker.com": {
      platform: "tapupup",
      platform_id: "21",
      api: "//special-api.tapupup.com/",
      env: "production",
   },
   "jp.theacebanker.com": {
      platform: "tapupup",
      platform_id: "21",
      api: "//special-api.tapupup.com/",
      env: "production",
   }
}


export default envConfig[window.location.host as keyof Environment];